<!--
 * @Description: ================== 游戏介绍 =================
 * @Date: 2022-05-13 18:08:07
 * @Author: Zero
 * @FilePath: \guobao-web\src\components\Game.vue
 * @LastEditors: Zero
 * @LastEditTime: 2022-06-14 14:51:49
-->
<template>
  <div class="page page-game">
    <div style="margin-top:20px">
      <img src="/banner.jpg" alt="" style="width: 100%" />
    </div>
    <h2 class="title">游戏介绍</h2>
    <p>《零界战区》2024年一款以咒术动画为架构的策略卡牌手游。游戏在延续原作经典剧情的同时，你将结识许多个性独特的咒术师，通过掌握各种独特咒术和领域技能，与威胁人类的咒灵展开激战。体验刺激战斗、探索未知领域、成为强大的咒术师，开启了自己险象环生、波澜壮阔的咒术师之旅。</p>
    <!-- <pre> -->
    <div class="content">
      <div class="content-item">
        <h4>轻松放置，极速成长</h4>
          枯燥练级自动完成，摆脱反复无聊的单调操作。
        <img src="/1.jpg" alt="" />
      </div>
      <div class="content-item">
        <h4>顶级咒师，任你差遣</h4>
          五条悟、虎杖悠仁、乙骨忧太、夏油杰、禅院直毘人等等顶级咒师，皆在你的掌控中！
        <img src="/2.jpg" alt="" />
      </div>
      <div class="content-item">
        <h4>咒技咒物，多种搭配</h4>
          每位咒术师除了自有的技能，还能搭配不同的咒技、咒物练就属于你自己的咒术师。
        <img src="/3.jpg" alt="" />
      </div>
      <div class="content-item">
        <h4>多种玩法，与众不同</h4>
          摆脱传统类游戏玩法单调的问题，加入多种不同副本、试炼等玩法，打造独一无二的游戏方式。
        <img src="/4.jpg" alt="" />
      </div>
    </div>
    <!-- </pre> -->
  </div>
</template>

<style scss>
.content-item {
  display: inline-block;
  width: 50%;
  padding: 12px;
  box-sizing: border-box;
  vertical-align: top;
}
.content-item img {
  display: block;
  margin-top: 12px;
  width: 400px;
}
@media screen and (max-width: 768px) {
  .content-item {
    width: 100%;
  }
  .content-item img {
    width: 100%;
  }
}
</style>
