<!--
 * @Description: ================== 技术支持及服务协议 =================
 * @Date: 2022-05-13 18:08:07
 * @Author: Zero
 * @FilePath: \guobao-web\src\components\Service.vue
 * @LastEditors: Zero
 * @LastEditTime: 2022-06-13 10:20:56
-->
<template>
  <div class="page page-game">
    <h2 class="title">技术支持及服务协议</h2>
    <pre>
如果你对本协议有任何疑问，请联系我们:
联系邮箱：a13229059052@163.com
如果您决定使用我们产品提供的服务，本协议用于告知我们对于用户的隐私如何收集、使用和披露。
如果您选择使用我们提供的服务，则表示您同意收集和使用与此政策相关的信息。我们收集的个人信息用于提供和改进服务。除非本协议中另有说明，否则我们不会与任何人一起使用或分享您的信息。
除非本协议另有规定，否则本协议中使用的术语与我们的条款和条件具有相同的含义，可在游戏中访问。

1、收集信息
我们收集您提供给我们的部分或全部信息， 以及您使用网站和应用程序的数据， 但我们将主要使用收集的数据为您提供满意的服务， 并提高您的使用体验。
1.1、设备和技术信息的收集
1.2、常规个人信息收集
在使用我们的服务时，我们可能会要求您向我们提供可用于联系或识别您的某些个人身份信息（“个人数据”），个人身份信息可能包括但不限于：

2、如何使用收集的信息
游戏将收集的数据用于以下其中的部分或全部目的：
• 提供和维护服务
• 通知您有关我们服务的更改
• 允许您在选择时参与我们服务的互动功能
• 为客户提供关怀和支持
• 提供分析或有价值的信息，以便我们改进服务
• 监控服务的使用情况
• 检测，预防和解决技术问题
当您通过移动设备访问服务时，我们可能会自动收集某些信息，包括但不限于您使用的移动设备类型，移动设备唯一ID，移动设备的IP地址，移动设备操作系统，您使用的移动互联网浏览器的类型，唯一的设备标识符和其他诊断数据（“使用数据”）。

3、信息存储
3.1、数据安全
数据的安全性对我们很重要，但请记住，没有任何安全措施是完美的或不可渗透的。虽然我们努力使用商业上可接受的方式来保护您的个人数据，但我们无法保证其绝对的安全性。
3.2、数据传输
您的信息（包括个人数据）可能会被转移到您所在州，省，国家或其他政府管辖范围之外的计算机上，并且这些计算机的数据保护法可能与您所在司法管辖区的数据保护法不同。
如果您位于中国境外并选择向我们提供信息，请注意我们将数据（包括个人数据）传输到中国并在中国处理。
您同意本协议，然后提交此类信息即表示您同意该转让。
3.3、数据披露
游戏可能会真诚地披露您的个人资料，相信此类行为是必要的：
• 履行法律义务
• 保护和捍卫本软件的权利或财产
• 防止或调查与服务相关的可能的不当行为
• 保护服务或公众用户的人身安全
• 防止法律责任
3.4、服务供应商
我们可能雇用第三方（公司或个人）来促进我们的服务（“服务提供商”）。第三方代表我们提供服务，执行相关服务或协助我们分析服务的使用方式。 第三方只能代表我们访问您的个人数据以执行这些任务，并且有义务不将其用于任何其他目的。
3.5、链接到其他网站
我们的服务可能包含指向非我们运营的其他网站的链接。如果您点击第三方链接，您将被引导至该第三方的网站。我们强烈建议您查看您访问的每个站点的协议。 我们无法控制任何第三方网站或服务的内容，协议或做法，也不承担任何责任。

4、删除信息
当不再需要信息时，我们将使用合理的措施删除它，以保护信息不被未经授权的访问或使用。

5、保护信息
我们已经实施了合理的安全措施，以保障收集信息的机密性、安全性和完整性，并防止未经授权的访问和使用违反本协议的信息。虽然我们采取合理的努力，以保障您的信息在我们维护时依然安全，但请注意，没有任何安全措施是绝对安全的。

6、与儿童有关的信息资料收集
我们非常重视对未成年人个人信息的保护。如果你是14周岁以下的未成年人，在使用我们的服务前，应确保事先取得监护人的同意，如果你是已满14不满18岁的未成年人，在使用我们服务前，应确保事先取得本人或监护人的同意。如果我们得知已收集了14周岁以下儿童的个人资料，我们会采取一切合理步骤，尽快删除有关资料。如果您知道您的孩子在未经您同意的情况下向我们提供了个人信息, 请与我们联系。

7、cookie技术
我们使用cookie和类似的跟踪技术来跟踪我们服务上的活动并保留某些信息。
Cookie是包含少量数据的文件，可能包含匿名唯一标识符。Cookie会从网站发送到您的浏览器并存储在您的设备上。还使用其他跟踪技术比如信标，标签和脚本等，用于收集和跟踪信息以及改进和分析我们的服务。
您可以指示您的浏览器拒绝所有cookie或指示何时发送cookie。但是，如果您不接受cookie，您可能无法使用我们服务的某些部分。
我们使用的Cookie示例：
• 会话Cookie。我们使用Session Cookies来运营我们的服务。
• 偏好Cookie。我们使用首选项Cookie来记住您的偏好和各种设置。
• 安全Cookies。出于安全考虑，我们使用安全Cookie。

8、第三方服务条款

9、统计分析
我们产品使用第三方统计，您需要一并同意第三方服务商提供的协议；
链接到应用程序使用的第三方服务提供商的协议

10、退出
根据本协议，您可以从您的每台设备上卸载所有应用程序并停止使用本网站，从而永远自由地选择不参与将来在软件内收集您的个人信息。

11、对此协议的更改
我们可能会不时更新我们的协议。我们将通过在此页面上发布新的协议来通知您任何更改。
建议您定期查看本协议以了解任何变更。本协议的更改在此页面上发布时生效。
    </pre>
  </div>
</template>
